@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-white url('../img/bg/Background_Plain.jpg') no-repeat center top;
	background-size: cover;
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
}

body {
	color: $color-white;
	min-height: 100vh;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

video {
	width: 100%;
}

.btn {
	border: 1px solid $color-blue;
	color: $color-blue;
	display: inline-block;
	font-size: 15px;
	padding: 10px 20px;
	text-align: center;
	text-transform: uppercase;
	transition: all 0.2s ease-in-out;
	vertical-align: middle;

	@include hover-focus {
		color: $color-blue;
		transform: scale(1.1);
	}

	@include phone-sm-down {
		font-size: 18px;
		padding: 6px 15px;
	}
}

header {
	align-items: center;
	border-bottom: 2px solid $color-blue;
	border-top: 2px solid $color-blue;
	display: flex;
	height: 100vh;
	justify-content: center;
	padding: 50px 0;
	position: relative;
	text-align: center;

	@include phone-down {
		padding: 30px 0;
	}

	h1 {
		color: $color-blue;
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 0.61px;
		line-height: 20px;
		margin: 0;
		text-transform: uppercase;

		> img {
			margin: 0 auto 30px auto;
			width: 97px;
		}
	}

	.img {
		margin: 0 auto 30px auto;
		width: rem(245);

		@include phone-down {
			width: 166px;
		}
	}

	.btn {
		font-size: 15px;
		width: rem(245);

		@include phone-down {
			width: 166px;
		}
	}


	> img {
		bottom: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: auto;

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}

}

main {
	text-align: center;

	#cta {
		border-bottom: 2px solid $color-blue;

		.container {
			max-width: 100%;
			padding: 0;
		}

		.video {
			position: relative;

			.embed-container {
				padding-bottom: 30%;
				position: relative;

				@include tablet-down {
					padding-bottom: 75%
				}

				> video {
					bottom: 0;
					height: 100%;
					left: 0;
					object-fit: cover;
					object-position: center top;
					position: absolute;
					right: 0;
					top: 0;
					width: 100%;
				}
			}

			> .btn {
				bottom: 30px;
				left: 0;
				margin: 0 auto;
				max-width: 320px;
				position: absolute;
				right: 0;

				@include hover-focus {
					transform: scale(1.1);
				}
			}
		}
	}

	#music {
		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			max-width: 100%;
			padding: 0;

			@include tablet-down {
				display: block;
			}

			.music-item {
				flex: 0 0 33.333333%;

				&:first-child {
					border-right: 1px solid $color-blue;
				}

				&:last-child {
					border-left: 1px solid $color-blue;
				}

				@include tablet-down {
					border: 2px solid $color-blue !important;
					&:last-child {
						border-bottom: 0 !important;
						border-top: 0 !important;
					}
				}

				span {
					color: $color-blue;
					display: block;
					font-size: 20px;
					padding-top: 60px;
					text-transform: uppercase;
				}

				h2 {
					margin: 40px 0;

					> img {
						height: 58px;
						margin: 0 auto;
						width: auto;

						@include phone-down {
							height: 48px;
						}
					}
				}

				.video {
					margin-top: 60px;
					position: relative;

					@include tablet-down {
						.embed-container {
							padding-bottom: 75%;
						}
					}

					> .btn {
						left: 0;
						margin: 0 auto;
						max-width: 320px;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);

						@include hover-focus {
							transform: translateY(-50%) scale(1.1);
						}
					}
				}
			}
		}
	}

}

footer {
	border-top: 2px solid $color-blue;
	padding: 25px 0 5px 0;
	text-align: center;

	.social-follow {
		margin-bottom: 25px;

		@include desktop-sm-down {
			display: flex;
			flex-direction: column-reverse;
			flex-wrap: nowrap;
		}

		.socials {
			display: inline-block;
			list-style: none;
			margin: 0 20px 0 0;
			padding: 0;
			vertical-align: middle;

			@include desktop-sm-down {
				display: block;
				margin: 20px 0 0 0;
			}

			> li {
				display: inline-block;
				font-size: 18px;
				vertical-align: middle;

				+ li {
					margin-left: 7px;
				}

				> a {
					color: $color-blue;
					display: inline-block;
					transition: all 0.2s ease-in-out;
					vertical-align: middle;

					@include hover-focus {
						transform: scale(1.1);
					}
				}
			}
		}

		.newsletter {
			display: inline-block;
			vertical-align: middle;

			@include desktop-sm-down {
				display: block;
			}

			.form-thanks {
				color: $color-blue;
				font-size: 11px;
				text-transform: uppercase;
			}

			> input {
				background: none;
				border: 0;
				border-bottom: 1px solid $color-blue;
				color: $color-blue;
				font-size: 11px;
				font-weight: 500;
				padding: 0 0 2px 0;
				text-align: center;

				&:focus {
					border-color: $color-white;
				}

				@include placeholder {
					color: $color-blue;
					text-transform: uppercase;
				}
			}

			> button {
				background: none;
				border: 1px solid $color-blue;
				color: $color-blue;
				font-size: 11px;
				font-weight: 500;
				margin-left: 3px;
				padding: 2px 5px;
				text-transform: uppercase;
				transition: all 0.2s ease-in-out;

				@include hover-focus {
					transform: scale(1.1);
				}
			}
		}
	}

	.copyright {
		color: $color-gray;
		font-size: 8px;
		line-height: 10px;
		margin: 0 auto;
		max-width: 450px;
		text-transform: uppercase;

		> a {
			display: inline-block;
			margin-bottom: 3px;
			vertical-align: middle;

			> svg {
				path {
					fill: $color-gray;
				}
			}

			@include hover-focus {
				> svg {
					path {
						fill: $color-white;
					}
				}
			}
		}

		p {
			margin: 0;

			a {
				color: $color-gray;

				@include hover-focus {
					color: $color-white;
				}
			}
		}
	}
}
